import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";

import TopANews from "./TopANews";

const languageComponents = {
  Chinese: React.lazy(() => import("./ClassicCN/ContentCN")),
  Deutsch: React.lazy(() => import("./ClassicDE/ContentDE")),
  Thai: React.lazy(() => import("./ClassicTH/ContentTH")),
  // French: React.lazy(() => import("./ClassicFR/ContentFR")),
  French: React.lazy(() => import("./ClassicFR/FrenchSupports")),
  English: React.lazy(() => import("./ClassicEN/EnglishSupports")),
};

export default function BasicSelect() {
  const [language, setLanguage] = useState("English");

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  const SelectedLanguageComponent = languageComponents[language];

  return (
    <>
      <TopANews />
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormControl
          sx={{
            minWidth: "230px",
            margin: 1,
          }}
          size="small"
        >
          <InputLabel id="language-select">使用言語</InputLabel>
          <Select
            labelId="language"
            id="language-select"
            value={language}
            label="language"
            onChange={handleChange}
          >
            <MenuItem value={"Chinese"}>🇨🇳 中国語（ベータ版）</MenuItem>
            <MenuItem value={"Deutsch"}>🇩🇪 ドイツ語（ベータ版）</MenuItem>
            <MenuItem value={"Thai"}>🇹🇭 タイ語（ベータ版）</MenuItem>
            <MenuItem value={"French"}>🇫🇷 フランス語（ベータ版）</MenuItem>
            <MenuItem value={"English"}>英語</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <React.Suspense fallback={<CircularProgress />}>
        <SelectedLanguageComponent />
      </React.Suspense>
    </>
  );
}
